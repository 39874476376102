<template lang="pug">
.page.index
  bokeh-backdrop.page__backdrop

  .page__inner
    hero-section#hero
    press-releases-section#press
    bloomberg-2024-section#bloomberg-2024
    products-collections-section#collections
    products-section#products
    features-section#features
    innovation-section#innovation
    locations-section#locations
    mission-section#mission
    testimonials-section#testimonials(
      :records="testimonials")
    partners-section#partners(
      :records="partners")
</template>

<script>
import BokehBackdrop from '~/components/home/BokehBackdrop'
import HeroSection from '~/components/home/HeroSection'
import PressReleasesSection from '~/components/home/PressReleasesSection'
import Bloomberg2024Section from '~/components/home/Bloomberg2024Section'
import ProductsCollectionsSection from '~/components/home/ProductsCollectionsSection'
import ProductsSection from '~/components/home/ProductsSection'
import FeaturesSection from '~/components/home/FeaturesSection'
import InnovationSection from '~/components/home/InnovationSection'
import LocationsSection from '~/components/home/LocationsSection'
import MissionSection from '~/components/home/MissionSection'
import TestimonialsSection from '~/components/home/TestimonialsSection'
import PartnersSection from '~/components/home/PartnersSection'

export default {
  name: 'Index',

  components: {
    BokehBackdrop,
    HeroSection,
    Bloomberg2024Section,
    PressReleasesSection,
    ProductsCollectionsSection,
    ProductsSection,
    FeaturesSection,
    InnovationSection,
    LocationsSection,
    MissionSection,
    TestimonialsSection,
    PartnersSection
  },

  data () {
    return {
      allTestimonials: []
    }
  },

  fetch () {
    return this.$api.common
      .getTestimonials({
        sort: 'published_at',
        direction: 'desc',
        per_page: 120
      })
      .then(res => {
        this.allTestimonials = res
      })
      .catch(this.$debug.log)
  },

  head: {
    title: 'Homepage',
    meta: [
      { name: 'google-site-verification', content: 'WScF--pHCcvrD-IMvDKhV2cEDPF1SzkE_L7DhkUIf3s' }
    ]
  },

  computed: {
    testimonials () {
      return this.allTestimonials
        .filter(i => i.featured && i.content)
    },

    partners () {
      return this.allTestimonials
        .filter(i => !i.featured && i.logo_small_url)
        .sort((a, b) => {
          if (a.priority !== b.priority)
            return a.priority - b.priority
          return 0
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  &__inner {
    overflow: hidden;
  }
  section {
    ::v-deep {
      h2 {
        font-size: 32px;
        line-height: 120%;
        @include media(md) {
          font-size: 40px;
        }
      }
      .el-button {
        font-size: 14px;
        border-radius: 16px;
        padding: 1em 2em;
        line-height: 120%;
      }
    }
  }
}
</style>
